@import "../button.scss";

.askQuestion {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &_rules {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &_rule {
    padding: 10px 16px 12px 46px;
    position: relative;

    &:nth-child(2n + 1) {
      background-color: rgba(138, 23, 2, 0.04);
    }

    &::before {
      content: url("./bullet.svg");
      position: absolute;
      top: 14px;
      left: 16px;
    }

    &:not(:last-child)::after {
      content: "";
      display: block;
      width: 1px;
      height: calc(100% - 18px);
      position: absolute;
      top: 32px;
      left: 24px;
      background-color: #8A1702;
    }

    &:first-child {
      padding-top: 21px;

      &::before {
        top: 24px;
      }

      &::after {
        top: 42px;
        height: calc(100% - 28px);
      }
    }

    h2 {
      font-size: 16px;
      line-height: 22px;
      font-weight: 700px;
      margin: 0 0 6px;
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &_form {
    display: flex;
    padding: 24px 20px 16px;
    flex-grow: 1;
    flex-direction: column;

    .freeFormInput {
      margin-top: 0;
    }
  }

  &_submit {
    @extend %filledButton;
    margin-top: auto;
  }
}

.submissionDone {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 24px;

  &_message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    svg {
      width: 100%;
      margin-bottom: 14px;
    }

    p {
      margin: 0;
    }
  }

  &_goBack {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font-family: inherit;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    color: #8A1702;
  }
}
