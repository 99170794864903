@import "../button.scss";

.introduction {
  display: grid;
  grid-template-rows: 1fr auto;
  padding: 24px 16px;
  min-height: 100vh;
  gap: 24px;

  &_content {
    text-align: center;
    align-self: center;
    justify-self: stretch;
    margin: 0;
  }

  &_go {
    @extend %filledButton;
  }
}
