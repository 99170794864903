.results {
  padding: 20px 0 0;

  &_title {
    margin: 0 20px 20px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    font-weight: 500;
    color: #b83822;
  }
}

.userResults {
  margin: 0 20px 20px;
  padding: 20px;
  background-image: linear-gradient(249.08deg, #dd735f 36.17%, #a51902 98.42%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  color: white;
  text-align: center;

  &_title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin: 0 0 8px;
  }

  &_points {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 7px;
  }

  &_pointsNumber {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
  }

  &_answers {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }
}

.duelResult {
  margin: 0 20px 20px;
  padding: 20px 16px;
  display: grid;
  grid-template-areas:
    "me      versus  mate"
    "outcome outcome outcome";
  grid-template-columns: 1fr auto 1fr;
  column-gap: 7px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.14);
  border-radius: 4px;

  &.-isEnded {
    row-gap: 20px;
  }

  &_outcome {
    grid-area: outcome;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #a51802;
    margin: 0;
  }

  &_versus {
    width: 34px;
    position: relative;
    align-self: stretch;
    justify-self: stretch;

    &::after {
      content: "VS";
      grid-area: versus;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: #f5eae9;
      box-shadow: 0 0 0 8px white;

      position: absolute;
      top: 36px;

      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #a51802;
    }

    &::before {
      content: "";
      width: 1px;
      height: 100%;
      background-color: #e4e4e4;

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.duelParticipant {
  text-align: center;

  &.-me {
    grid-area: me;
  }

  &.-mate {
    grid-area: mate;
    color: #909090;
  }

  &_stillAnswering {
    margin: 37px 0 0;

    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }

  &_name {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin: 0 0 8px;
  }

  &_pointsNumber {
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    margin: 0 0 8px;
    color: #909090;
  }

  &_answers {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }

  &.-isWinner &_pointsNumber {
    color: #a51802;
  }
}

.rating {
  &_header {
    margin: 0 20px;
    padding: 12px 0 11px;
    display: grid;
    grid-template-areas:
      "title  bonus"
      "places bonus";
    grid-template-columns: 1fr auto;
    border-bottom: 1px solid #8a1702;
  }

  &_title,
  &_places {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }

  &_title {
    font-weight: 500;
    grid-area: title;
  }

  &_places {
    color: #909090;
    grid-area: places;
  }

  &_bonus {
    display: flex;
    align-self: center;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    grid-area: bonus;

    svg {
      margin-left: 4px;
    }
  }

  &_records {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: records var(--startPlace, 0);
  }
}

.record {
  display: grid;
  grid-template-areas:
    "counter name points"
    "counter name time";
  grid-template-columns: auto 1fr auto;
  column-gap: 16px;
  counter-increment: records;
  padding: 13px 20px;

  &:not(:last-child):not(.-me) {
    padding-bottom: 12px;
    border-bottom: 1px solid #e4e4e4;
  }

  &.-me {
    background-color: #f5eae9;
  }

  &::before {
    content: counter(records);
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background-color: #8a1702;
    color: white;
    font-size: 17px;
    line-height: 20.15px;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-area: counter;
  }

  &_name {
    grid-area: name;
    margin: 0;
    align-self: center;
    font-size: 14px;
    line-height: 18px;
  }

  &_points {
    grid-area: points;
    margin: 0;
    text-align: center;
    font-size: 17px;
    line-height: 20.15px;
    color: #8a1702;
  }

  &_time {
    grid-area: time;
    margin: 0;
    color: #909090;
    font-size: 14px;
    line-height: 18px;

    &::before {
      content: url("./time.svg");
      position: relative;
      top: 2px;
      margin-right: 4px;
    }
  }
}
