.rules {
  padding: 24px 16px;
  font-size: 16px;
  line-height: 22px;

  h2 {
    line-height: (22 / 16);

    &:first-child {
      margin-top: 0;
    }
  }
}
