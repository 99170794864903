.quiz {
  padding: 0 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &_header {
    display: flex;
    justify-content: space-between;
    margin: 9px 0 17px;
  }

  &_nthQuestion {
    color: #909090;
    font-size: 14px;
    line-height: 18px;
    margin: 15px 0;
  }
}

.progress {
  display: grid;
  grid-template-columns: 55px 1fr 55px;
  gap: 12px;
  margin-bottom: 20px;

  &_goButton {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(165, 24, 2, 0.2);
    outline: none;

    &.-forward {
      transform: scaleX(-1);
    }

    &:disabled {
      background-color: rgba(144, 144, 144, 0.2);

      svg {
        fill: #909090;
      }
    }

    &.-finish:not(:disabled) {
      background-color: #a51802;

      svg {
        fill: white;
      }
    }

    svg {
      fill: #a51802;
    }
  }

  &_dots {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  &_dot {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #edd1cc;

    &.-active {
      background-color: #a51802;
    }

    &.-small {
      width: 8px;
      height: 8px;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
