.spinner {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 24px;
  border: 4px solid #8a1702;
  border-bottom-color: transparent;
  animation-name: spinner;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}
