.duel {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_searching {
    margin: 0 0 48px;
  }

  &_preparing {
    margin: 0 0 16px;
  }

  &_mateName {
    margin: 0 0 48px;
    opacity: 0.75;
  }
}
