%button {
  display: flex;
  width: 100%;
  height: 55px;
  border-radius: 100px;
  text-align: center;
  border: none;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  outline: none;

  &:disabled {
    opacity: 0.5;
  }
}

%filledButton {
  @extend %button;
  color: white;
  background-color: #a51802;
}

%outlinedButton {
  @extend %button;
  color: #a51802;
  background-color: white;
  border: 1px solid #a51802;
}
