@import "../button.scss";

.stages {
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: 100vh;

  &_footer {
    margin-top: auto;
  }
}

.stage {
  background-color: white;
  padding: 25px 26px 16px;
  border-radius: 4px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  &_tour {
    font-weight: 300;
    color: #a51802;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin: 0 0 4px;
  }

  &_title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin: 0 0 21px;
  }

  &_result {
    text-align: center;
    color: #a51802;
    font-size: 14px;
    line-height: 18px;
    margin: 20px 0;
  }

  &_button {
    &.-take {
      @extend %filledButton;
    }

    &.-ratings {
      @extend %outlinedButton;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.overallResults {
  @extend %outlinedButton;
  margin-top: auto;
  margin-bottom: 16px;
}

.links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  a {
    color: inherit;
  }

  li:not(:last-child) {
    margin-right: 16px;
  }
}
