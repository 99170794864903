@font-face {
  font-family: Rubik;
  font-weight: 300;
  src: url("./Rubik-Light.ttf");
}

@font-face {
  font-family: Rubik;
  font-weight: 400;
  src: url("./Rubik-Regular.ttf");
}

@font-face {
  font-family: Rubik;
  font-weight: 500;
  src: url("./Rubik-Medium.ttf");
}

@font-face {
  font-family: Rubik;
  font-weight: 700;
  src: url("./Rubik-Bold.ttf");
}
