@import "../fonts/fonts.scss";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Rubik, sans-serif;
  color: #333333;
}

.signIn {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  height: 100vh;
  margin: 0;
  padding: 20px;
  background-color: #8a1702;
  color: white;
}
