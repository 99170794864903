.question {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:empty {
      display: none;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  iframe {
    width: 100%;
    height: calc((100vw - 20px * 2) / 16 * 9);
  }
}

.freeFormInput {
  display: block;
  background-color: white;
  border-radius: 4px;
  padding: 20px 16px;
  box-shadow: 0 2px 5px rgb(#000000, 14%);
  margin-top: auto;
  margin-bottom: 20px;

  &.-sticky {
    position: sticky;
    bottom: 20px;
  }

  &_label {
    display: block;
    color: #909090;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 8px;
  }

  &_input {
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    border-radius: none;
    border-bottom: 1px solid #c8c8c8;
    outline: none;
    font-family: inherit;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 5px;
    caret-color: #a51802;

    &:focus {
      border-bottom: 2px solid #a51802;
      padding-bottom: 4px;
    }
  }
}

.choice {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.option {
  width: 100%;
  border-radius: (55px / 2);
  padding: 16px 24px;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.14);
  position: relative;
  border: 1px solid transparent;
  display: flex;
  align-items: center;

  &.-chosen {
    border-color: #a51802;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  input {
    appearance: none;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    opacity: 0;
  }

  &_label {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    flex-grow: 1;
    margin-right: 56px;

    p {
      margin: 0;
    }
  }

  &_icon {
    flex-shrink: 0;
  }
}

.timeout {
  &_bar {
    stroke: #c8c8c8;
    stroke-width: 4px;
    fill: none;
  }

  &_filled {
    stroke: #a51802;
    stroke-width: 4px;
    fill: none;
  }

  &_time {
    text-anchor: middle;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    fill: #909090;
  }
}
